import { SchoolLocale } from "@app/locales/localeid";
import { ChangeLogType } from "@app/util";
import { withRouter } from "gl-commonui";
import React from "react";
import { ChangeLog, ChangeLogProps } from "./component/change-log";
import { StateType } from "@app/states";
import { connect } from "react-redux";
import {
    getPendingChanges,
    setLoading,
    getPendingChangesDetail,
    approveDenyPending,
    bulkApproveDenyPending,
    resetChangeFieldData,
    setRefreshDataFlag,
    reload
} from "@app/states/cims";

const SchoolChangeLog = (props: ChangeLogProps) => {
    return (
        <ChangeLog
            {...props}
            logTypes={[ChangeLogType.School, ChangeLogType.Campus, ChangeLogType.SchoolClass]}
            searchPlaceHolderId={SchoolLocale.CIMSSchoolLogSearchPlaceholder}
            match={props.match}
            history={props.history}
        />
    );
};

export const SchoolChangeLogPage = withRouter(
    connect(
        ({
            cims: {
                refreshData,
                pendingChangesData,
                totalData,
                loading,
                loading4Modal,
                changeFieldData,
                clearSelected,
                failedBulkApproveDeny
            }
        }: StateType) => ({
            refreshData,
            pendingChangesData,
            totalData,
            loading,
            loading4Modal,
            changeFieldData,
            clearSelected,
            failedBulkApproveDeny
        }),
        {
            getPendingChanges,
            setLoading,
            getPendingChangesDetail,
            approveDenyPending,
            bulkApproveDenyPending,
            resetChangeFieldData,
            setRefreshDataFlag,
            reload
        }
    )(SchoolChangeLog)
);
